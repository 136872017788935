.editMenu {
    text-align: right;
    padding: 0;
    .dropdown {
        min-width: 10px;
        button {
            &::after {
                display: none;
            }
            i {
                margin: 0;
            }
        }
        i {
            margin-right: 0.8rem;
            position: relative;
            top: 2px;
        }
    }
}
.orWrap {
    margin-top: -26px;
    margin-left: calc(50% - 30px);
    color: gray;
    font-size: 12px;
    font-weight: bold;
    background: white;
    width: fit-content;
    padding: 0 8px;
    border-radius: 10px;
}
.referencePage .exportIcons {
    top: 12px;
    a {
        font-weight: 600 !important;
    }
}
.referencePage {
    .leftWrap{
        padding: 2rem 4rem 14rem 4rem;
    }
    #cardWrap {
        max-height: 99vh;
    }
    table {
        tr {
            height: 2rem !important;
        }
        td,
        th {
            vertical-align: top !important;
            min-width: 100px;
            white-space: nowrap;
        }
        th.right,
        td.right {
            text-align: right;
        }
        th {
            text-transform: capitalize;
        }
        .addIcon {
            font-size: 1.3rem;
            position: relative;
            top: 2px;
            &:hover {
                opacity: 0.5;
            }
        }
    }

    .bucketLogo {
        position: absolute;
        right: 0;
        z-index: 12;
        top: 6rem;
        width: 100%;
        .logo {
            color: white;
            text-align: right;
            i {
                cursor: pointer;
            }
        }
    }
    .bucketWrap {
        position: absolute;
        z-index: 13;
        top: 26px;
        right: 0;
        width: 400px;
        .bucketList {
            max-height: calc(100vh - 200px);
            overflow: auto;
        }
    }
    .card {
        overflow: visible;
    }
}
