.page.adminMessagePage {
    .titled.secondary.card .card-title {
        border-bottom: none ;
        color: #2a2b2c;
    }

    td {
        vertical-align: middle !important;
    }

}