.hubPage {
    .backtohistorypage {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .list-group {
        i {
            font-size: 1.3rem;
            position: relative;
            top: 3px;
        }
    }

    .arrowList {
        margin-top: 1rem;
    }

    .card.content {
        padding: 1.5rem 0rem 1.5rem 2rem;
        border: none !important;
        box-shadow: none !important;
        min-height: 400px;
    }

    .repTitle {
        cursor: pointer;
    }

    table {
        thead {
            th {
                > input,
                > select {
                    width: 100%;
                    min-width: 150px;
                }

                .fds-datetime-range {
                    width: 220px;
                }
            }
        }

        tbody {
            tr.selected {
                td:nth-child(1) {
                    border-left: 3px solid teal;
                }
            }
        }

        td {
            vertical-align: middle !important;

            .EXT_EVT {
                width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                margin-top: -6px;
            }

            .childTitle {
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .dropdown .dropdown-menu.show {
        transform: translateX(10px) !important;
    }

    .fds-tag i:before {
        cursor: default;
    }

    .visualize {
        margin: 1rem auto;
        padding: 1rem;
        background: #d9d9d9;

        .close {
            position: fixed;
            right: 2%;
            top: 2%;
            z-index: 200;
            font-size: 2rem;
            cursor: pointer;

            :hover {
                color: gray;
            }
        }
    }
}
