@import "@arctravel/react-fds/lib/designTokens/color.scss";

.adminInfoPage,
.adminDetailsPage,
.adminDashboardPage {
    .fds-pl .fds-pl-column {
        min-height: auto !important;
    }
}

.adminDashboardPage {
    height: calc(100vh - 240px);
    background-color: $gray-100;
    .fds-pl-jumbotron {
        text-align: center;
    }
    .fds-pl-container {
        background-color: $gray-100;
        padding: 1rem;
    }
}

.adminDetailsPage {
    .hidable {
        display: none;
    }
    .show,
    .show + div {
        .hidable {
            display: inline-block;
        }
    }
    .show {
        small {
            display: none;
        }
    }
}

.customerDetailSummary > div {
    padding-bottom: 1rem;
}

.custom-toggle.form-check {

    .form-check-input:not([disabled]) {
        border-color: $gray-500;
        &.form-switch {
            background-color: $gray-500;
        }
    }

    .form-check-input:checked:not([disabled]) {
        border-color: $teal-500;
        &.form-switch {
            background-color: $teal-500;
        }
    }
    .form-check-input:disabled {
        border-color: $gray-500;
        background-color: $gray-300;
    }

    .form-check-input:disabled:checked {
        border-color: $gray-500;
        background-color: $gray-300;
    }

    .form-check-input:not([disabled]):hover {
        border-color: $teal-arc-brand;
        background-color: $teal-arc-brand;
    }

    .form-check-input:checked:not([disabled]):focus {
        outline: 0;
        &.form-swith {
            background-color: $teal-500;
        }
    }

    .form-check-input:not([disabled]):focus {
        outline: 0;
        &.form-swith {
            background-color: $teal-500;
        }
    }
}
