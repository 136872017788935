@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.arcciCorpWrap {
    border: 1px solid #ccc;
    border-bottom: none;
    background: $gray-100;
    .tcvIcon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 1.4rem;
    }
    .dot.undefined {
        background-color: white;
    }
    .bg-dark {
        background-color: black;
    }
    .initialWrap {
        max-width: 48px;
        padding-top: 9px;
    }
    .ciRow {
        padding: 0.5rem;
        border-bottom: 1px solid #ccc;
        .contactWrap {
            .shortName {
                margin-top: 0.6rem;
            }
            .lessMargin {
                margin-top: -2px;
            }
        }
    }
    .tmcRight *{
        white-space: break-spaces;
    }
    &.tmc {
        border: 1px solid #ccc;
        .tmcLeft {
            padding: 0.5rem;
            background: #f0f0f5;
            border-right: 1px solid #ccc;
            .dot {
                margin-top: 12px;
            }
        }
        .tmcRight {
            padding: 0.5rem;
        }
    }

    div[role="button"]:hover,
    &[role="button"]:hover,
    &.boxShadow:hover {
        box-shadow: 0px 0px 5px $primary !important;
    }
}

.tcvModal {
    .modal-dialog {
        max-width: 90%;
    }
}
