@import "~@arctravel/react-fds/lib/designTokens/color.scss";
@import "@arctravel/react-fds/lib/index.scss";
@import "@arctravel/react-fds/lib/designTokens/breakpoints.scss";

html body {
    svg,
    svg *,
    g,
    path,
    rect,
    text {
        font-family: "Source Sans Pro", Arial, sans-serif !important;
    }
    select.form-control {
        appearance: auto;
    }
    optgroup[label] {
        color: black;
        font-weight: 600;
        option {
            color: $teal-600;
        }
    }
    fieldset {
        display: contents;
    }
    .fds-accordion {
        border: none;
        box-shadow: none;
        .card {
            border-top: 1px solid #ccc;
            border-bottom: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            .collapse + .card-header {
                background: none;
                border: none;
            }
        }
        .collapser {
            text-align: right;
            i,
            span {
                cursor: pointer;
            }
        }
    }
}

.chartbg {
    background: #f3f3f3 !important;
}

.leftWrap {
    width: 450px;
    margin-bottom: -20px;
    border-right: 30px solid $teal-600;
    position: relative;
    flex: initial;
    background: $gray-100;
    padding: 2rem 4rem 9rem 4rem;
    transition: all 0.25s linear;
    &#reportPageLeftToggleRef {
        &.static,
        &.charts {
            .saved-reports {
                .select-flex {
                    display: block;
                    .select {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
            small.info {
                padding: 1rem 3.5rem 0 1.5rem;
                display: inline-block;
            }
        }
        &.charts {
            .actionBtnsWrap {
                button:nth-child(1) {
                    margin-left: 1rem;
                }
                button:nth-child(3) {
                    margin-right: 1rem;
                }
            }
        }
    }
    &.col.static {
        width: 380px !important;
        padding: 2px 2px 3rem 2px !important;
        border-right: none !important;
        background: transparent;
        .arrowContent {
            .card {
                border: none !important;
                border-radius: 0%;
            }
            small.info {
                padding: 1rem 1.5rem 0 1.5rem;
                display: inline-block;
            }
        }
    }
    &.charts {
        border-width: 0px !important;
        width: 450px !important;
        padding: 0 0 3rem 0 !important;
        background: white !important;
        button.minBtn {
            border: none !important;
            border-radius: 0% !important;
            padding: 0 !important;
            top: -50px;
            right: 50px;
            width: 30px;
            height: 30px;
            i {
                color: #000 !important;
                font-size: 2rem !important;
                &::before {
                    content: "\e924";
                }
            }
        }
        .arrowContent {
            .card {
                border: none;
                box-shadow: none;
            }
        }
    }
    &.min.charts {
        width: 0px !important;
        border: 0 !important;
        .minimize button {
            width: 48px;
            left: -28px;
            top: -8px;
            height: 70px;
            border-radius: 4px !important;
            border: 1px solid #ccc !important;
            background: white !important;
            position: absolute;
            z-index: 10;
            transform: rotate(0deg) !important;
            i::before {
                content: "\e97b";
            }
        }
    }
    .fds-datetime-range {
        width: 100%;
    }

    .blankable {
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
    }
}

.minimize {
    position: absolute;
    z-index: 20;
    right: -26px;
    top: 60px;
    button {
        width: 60px;
        height: 60px;
        border: 1px solid $primary !important;
        background-color: $white !important;
        padding: 0 !important;
        border-radius: 100%;
        &.btn:focus:before {
            border: none;
        }
        &:hover {
            background-color: $white;
            border: 1px solid $primary;
            i {
                color: $primary;
            }
        }
        i {
            color: $gray-400;
            font-size: 36px;
        }
    }
}

.leftWrap,
#chartFiltersWrap {
    &.min {
        width: 68px !important;
        padding: 0 !important;
        .blankable {
            opacity: 0;
            width: 1px;
            position: absolute;
            height: 100%;
            overflow: hidden;
        }
        .minimize {
            button {
                transform: rotate(180deg);
            }
        }
    }
}

#chartFiltersWrap {
    .minimize {
        button {
            top: -90px;
        }
    }
    &.min {
        width: 0px !important;
        border-right: 0px solid $teal-600 !important;
        .minimize {
            button {
                left: 8px;
                top: -90px;
                border-radius: 0;
                padding: 0 !important;
                width: 35px;
            }
        }
    }
}

.notifAlert {
    margin: 1rem 20%;
    position: absolute;
    top: 90px;
    width: 60%;
    z-index: 3;
}

.notifTitle {
    text-transform: capitalize;
}

.fds-tag {
    white-space: normal;
}

.fds-pl-column {
    min-height: calc(100vh - 350px);
}

.dropdown {
    min-width: auto;
    button {
        display: inline-block;
    }
    .adminLink {
        color: $teal-500 !important;
        + button i::before {
            color: $teal-500 !important;
        }
    }
}

.toast .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
        50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
}

.monthYearPicker {
    width: 100%;
    .pickerWrap {
        z-index: 2;
    }
}

.right {
    text-align: right;
    .monthYearPicker {
        .pickerWrap {
            right: 1rem;
        }
    }
}

.fds-typeahead {
    .form-control.ip {
        padding-right: 3rem;
    }
    .filteredWrap {
        li.top {
            &::before {
                content: "G";
                left: -8px;
                top: 6px;
                font-size: 12px;
                font-weight: bolder;
                color: rgb(4, 10, 85);
                border: 1px solid rgb(4, 10, 85);
                border-radius: 100%;
                display: inline-block;
                width: 1.3rem;
                height: 1.3rem;
                padding: 0 0.05rem 0 0.3rem;
                font-family: "fds-glyphs" !important;
            }
            &:hover,
            &.hover {
                &::before {
                    color: white;
                    border-color: white;
                }
            }
        }
    }
}

.react-dropdown-tree-select {
    height: 42px;
    .root.searchModeOn .node .toggle {
        display: inline-block !important;
    }
    .dropdown-content {
        li {
            position: relative;
            padding: 0.5rem 0;
            width: 100%;
            label {
                white-space: nowrap;
                margin: 0;
                &[title*=":"]::before {
                    content: "G";
                    position: absolute;
                    left: 0;
                    top: 11px;
                    line-height: 1rem;
                    font-size: 12px;
                    font-weight: bolder;
                    color: rgb(4, 10, 85);
                    border: 1px solid rgb(4, 10, 85);
                    border-radius: 100%;
                    display: inline-block;
                    width: 1.2rem;
                    height: 1.2rem;
                    padding: 0 0 0 0.25rem;
                    font-family: "fds-glyphs" !important;
                }
            }
        }
    }
}

.react-dropdown-tree-select {
    .infinite-scroll-component {
        padding: 0;
        .node {
            .toggle {
                margin-left: 12px;
            }
            label {
                span {
                    white-space: break-spaces;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }
}

.editMenu {
    text-align: right;
    .dropdown {
        min-width: 10px;
        width: 100%;
        button {
            margin: 0;
            //padding: 0 !important;
            font-size: 1.5rem;
            width: 100%;
            &::after {
                display: none;
            }
            i {
                margin: 0;
            }
        }
        i {
            margin-right: 0.8rem;
            position: relative;
            top: 2px;
        }
    }
}

.successTxt {
    color: $success !important;
}
.dangerTxt {
    color: $danger !important;
}
.infoTxt {
    color: $info !important;
}
.warningTxt {
    color: $warning !important;
}

table th {
    white-space: nowrap;

    i {
        float: right;
    }
}

.arrowList {
    margin-top: 7rem;
}

.arrowList {
    .list-group-item {
        margin-bottom: 1rem;
        margin-right: 1rem;
        border: none;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        cursor: pointer;
        background-color: transparent;
        font-weight: 600;
        padding: 1.25rem;

        &.active {
            box-shadow:
                0 1px 1px 0 rgba(0, 0, 0, 0.14),
                0 2px 1px -1px rgba(0, 0, 0, 0.12),
                0 1px 3px 0 rgba(0, 0, 0, 0.2);
        }

        &.active::after {
            content: "";
            position: absolute;
            top: 0;
            right: -24px;
            width: 0;
            height: 100%;
            border-top: 31px solid transparent;
            border-bottom: 31px solid transparent;
            border-left: 24px solid $primary;
        }
    }
}

.arrowContent {
    .card {
        overflow: visible;
    }
    .card.content {
        min-height: 300px;
        //overflow: hidden;
        padding: 1rem 1rem 1rem 2.5rem;
    }
    .select-flex {
        @include fds-xl {
            display: flex;
        }
    }
    .saved-reports {
        .select {
            width: 100%;
            margin-bottom: 1rem;

            @include fds-xl {
                width: 50%;
                margin-bottom: 0;
            }
        }
    }
}

.preview {
    border: 1px dashed teal;
    padding: 0.5rem;
    margin-top: 1rem;
    overflow: auto;
    &::before {
        content: "Preview";
        position: absolute;
        font-style: italic;
        background-color: white;
        padding: 0 8px;
        z-index: 10;
        margin-top: -18px;
        font-size: 10px;
        font-weight: bold;
    }
}

html body {
    .pvtUi {
        border: 1px solid #ccc;
        * {
            font-size: 0.89rem !important;
        }
        .pvtVals {
            border: none;
            .ico * {
                font-size: 12px !important;
                color: gray;
            }
        }
        .pvtVal {
            font-size: 1rem !important;
        }
        .pvtRowLabel,
        .pvtColLabel {
            font-weight: 600;
            cursor: default !important;
        }
        .pvtColLabel {
            background: #fcf5bd;
            &.metrics {
                font-weight: 700;
                color: #000;
            }
        }
        .pvtAttr {
            cursor: move;
            position: relative;
            .pivotClose {
                margin-left: 0.5rem;
                position: absolute;
                top: 11px;
                right: 6px;
                cursor: pointer;
                &:hover {
                    color: #353535;
                }
            }
        }
        .pvtRows {
            div {
                border: 1px dashed #a19f9f;
                display: inline-block;
                padding: 6px;
                border-radius: 4px;
                color: black;
                font-weight: 600;
                width: 94%;
                margin-left: 3%;
                text-align: center;
            }
            li:last-child {
                &::after {
                    content: "New Row";
                    border: 1px dashed #a19f9f;
                    width: 94%;
                    display: block;
                    position: relative;
                    padding: 8px;
                    margin: 3%;
                    border-radius: 4px;
                    color: black;
                    font-weight: 600;
                    cursor: default;
                    text-align: center;
                }
            }
            .pvtAttr {
                background-color: white;
                color: black;
                margin-left: 3%;
                &::before {
                    color: #a19f9f;
                }
            }
        }
        .pvtCols {
            div {
                border: 1px dashed #a19f9f;
                display: inline-block;
                padding: 6px;
                border-radius: 4px;
                color: black;
                font-weight: 600;
                text-align: center;
            }
            li:nth-last-child(2) {
                &::after {
                    content: "New Column";
                    border: 1px dashed #a19f9f;
                    width: 100px;
                    display: inline-block;
                    position: absolute;
                    padding: 6px 8px;
                    margin-left: 10px;
                    border-radius: 4px;
                    color: black;
                    font-weight: 600;
                    cursor: default;
                    text-align: center;
                }
            }
            .pvtAttr {
                background-color: white;
                color: black;
                &::before {
                    color: #a19f9f;
                }
            }
        }
        .pvtAxisContainer {
            border: none;
            cursor: default !important;
            &.pvtVertList {
                background: #c7e3ff !important;
                padding-top: 4px;
            }
            &.pvtHorizList {
                background: #fcf5bd !important;
                vertical-align: middle !important;
                padding: 0 0 0 4px;
                display: revert !important;
                li {
                    margin-left: 2px;
                    &:last-child {
                        display: none;
                    }
                }
            }
            padding: 0;
            li {
                padding: 2px 0px;
                cursor: grab !important;
                span.pvtAttr {
                    width: 94%;
                    padding: 7px 42px 7px 16px;
                    display: inline-block;
                    font-weight: 600;
                    border-radius: 4px;
                    &::before {
                        content: "\e979";
                        font-family: "fds-glyphs" !important;
                        position: absolute;
                        font-size: 1.3rem;
                        overflow: visible;
                        display: inline-block;
                        margin-left: -19px;
                        margin-top: -5px;
                        font-weight: 100;
                    }
                    .pvtTriangle {
                        display: none;
                    }
                }
            }
        }
        .pvtOutput {
            padding: 0;
            .pvtAxisLabel {
                color: #000;
                cursor: default !important;
                background-color: #44a3ff;
                &:hover {
                    background-color: #44a3ff;
                }
                &.col {
                    background-color: #b1cf6d !important;
                }
                &.metrics {
                    min-width: 120px;
                    color: #000;
                    background-color: #59cadd !important;
                    &::after {
                        content: "\e972";
                        font-family: "fds-glyphs" !important;
                        position: absolute;
                        margin-left: 9px;
                        margin-top: 1px;
                    }
                }
            }
            .pvtTable {
                width: calc(100% - 4px) !important;
                border: none !important;
                outline: none;
            }
            th.pvtTotalLabel.col,
            .rowTotal,
            .colTotal,
            .pvtTotal,
            .pvtGrandTotal {
                display: none;
                height: 0;
            }
        }

        .pvtVals {
            background: #c7e3ff !important;
        }

        .pvtRenderers {
            min-width: 150px;
            text-align: center;
            vertical-align: middle !important;
            color: #000;
            &::before {
                content: "\e971";
                font-family: "fds-glyphs" !important;
                position: absolute;
                margin-left: -19px;
                margin-top: 2px;
            }
            &::after {
                content: "\e972";
                font-family: "fds-glyphs" !important;
                position: absolute;
                margin-left: 9px;
                margin-top: 1px;
            }
        }

        .pvtRenderers,
        .pvtRenderers:hover,
        .pvtRenderers + .pvtUnused {
            background-color: #f3f3f3 !important;
            cursor: default !important;
        }

        .pvtRenderers + .pvtVals {
            display: none !important;
        }
    }
}

.JOURNEY {
    .arrowContent {
        .card.content {
            padding: 1rem;
        }
    }
}

html body {
    select,
    .select,
    input[type*="text"],
    .fds-typeahead,
    .tagWrap.disabled,
    .tagWrap.individual,
    .react-dropdown-tree-select,
    .react-datepicker__input-container {
        max-width: 400px;
    }
}
// Temporary overrides for FDS header to account for utility overlap
// TODO: Remove these once the FDS Header component is mobile friendly and implemented
.navbar .container {
    margin: 0 15px;
    max-width: 100%;
}

.navbar-brand {
    font-size: 1.5rem;
}

.navbar-utility .icon.dropdown {
    padding: 0;
    margin-right: 10px !important;
}

@media (min-width: 768px) {
    .navbar-expand-sm .navbar-toggler {
        display: block;
    }
    .navbar-expand-sm .navbar-collapse {
        display: none !important;
    }
}

@media (min-width: 1024px) {
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
    }
    .navbar .navbar-utility,
    .navbar-light.navbar .navbar-utility {
        position: absolute;
        top: 11px;
    }
    .navbar .container,
    .navbar-light.navbar .container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media (min-width: 1440px) {
    .navbar-brand {
        font-size: 2rem;
    }
}
.codeCoverage {
    position: absolute;
    z-index: 10;
    right: 0;
    margin-top: -3rem;
    margin-right: 1.2rem;
}

.alert {
    [class*="fds-glyphs"] {
        margin-right: 0.7rem;
    }
    // overwrite for homepage alert close button
    .fds-glyphs-clear {
        margin-right: 0;
    }
}

.homeAlert {
    border-radius: 0;
}

.btn-link {
    text-decoration: none !important;
}
.notifWrap {
    width: 500px !important;
    min-width: 360px !important;
    max-width: 500px !important;
    overflow-y: auto !important;
}

.fds-pl .fds-pl-column {
    min-height: 60vh !important;
    overflow: visible !important;
}

.canHide {
    &:not(.danger) {
        display: none;
    }
}

.nav-scoped.nav-tabs .nav-item button:disabled {
    color: #ccc;
}

.fds-pl-container.dataRefresh {
    position: relative;
    top: -1.8rem;
    margin-right: 2rem !important;
    z-index: 100;
    height: 1.7rem;
    text-align: right;
    color: white;
    font-size: 1rem;
}

.reportingPage .fds-pl-container.dataRefresh {
    top: -0.5rem;
}

.authLoader {
    top: calc(50% - 22px);
    position: fixed;
    left: calc(50% - 22px);
}

#viewAsWrap {
    background-color: $teal-600;
    color: $white;
    display: flex;
    a {
        color: $white;
        margin-left: auto;
    }
    span.txt {
        margin-right: 0.5rem;
    }
    select {
        height: 24px;
        //background: #047878;
        margin: 2px 0.5rem 2px 0;
        //color: white;
        border: none;
        font-weight: 600;
        //text-transform: uppercase;
    }
    .message {
        padding-right: 2rem;
    }
}

.arcFilter {
    filter: brightness(0.3) saturate(0%);
    -webkit-filter: brightness(0.3) saturate(0%);
}

.notifWrap .navTxt .notifDesc {
    white-space: wrap !important;
}
.capitalize {
    text-transform: capitalize;
}

.initial {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    text-align: center;
    margin-right: 0.5rem;
    font-weight: bold;
    background: $primary;
    color: white;
    font-family: monospace;
    padding-top: 6px;
    &.success {
        background: $success;
    }
    &.danger {
        background: $danger;
    }
    &.warning {
        background: $warning;
    }
    &.info {
        background: $info;
    }
    &.disabled {
        background: $gray-300;
    }
}

.dot {
    display: inline-block;
    border-radius: 100px;
    background: $primary;
    width: 14px;
    height: 14px;
    padding: 0;
    margin: 0 6px 0 0;
    position: relative;
    top: 1px;
    &.d25 {
        width: 7px;
        height: 7px;
        border-top-left-radius: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: inline-block;
        margin-left: 8px;
        margin-bottom: 8px;
    }
    &.d50 {
        width: 7px;
        height: 14px;
        border-top-left-radius: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 100px;
        display: inline-block;
        margin-left: 8px;
    }
    &.d75 {
        width: 7px;
        height: 14px;
        border-top-left-radius: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 100px;
        display: inline-block;
        margin-left: 8px;
        &::before {
            content: "";
            width: 7px;
            height: 7px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 100px;
            border-bottom-right-radius: 0px;
            display: inline-block;
            margin-left: -7px;
            top: 7px;
            padding: 0;
            position: absolute;
        }
    }
    &.xs {
        width: 10px;
        height: 10px;
        &.d50,
        &.d75 {
            width: 5px;
        }
        &.d75::before {
            width: 5px;
            height: 5px;
            margin-left: -5px;
            top: 5px;
        }
    }
    &.sm {
        width: 14px;
        height: 14px;
        &.d50,
        &.d75 {
            width: 7px;
        }
        &.d75::before {
            width: 7px;
            height: 7px;
            margin-left: -7px;
            top: 7px;
        }
    }
    &.lg {
        width: 18px;
        height: 18px;
        &.d50,
        &.d75 {
            width: 9px;
        }
        &.d75::before {
            width: 9px;
            height: 9px;
            margin-left: -9px;
            top: 9px;
        }
    }
    &.xl {
        width: 22px;
        height: 22px;
        &.d50,
        &.d75 {
            width: 11px;
        }
        &.d75::before {
            width: 11px;
            height: 11px;
            margin-left: -11px;
            top: 11px;
        }
    }
    &.xxl {
        width: 26px;
        height: 26px;
        &.d50,
        &.d75 {
            width: 13px;
        }
        &.d75::before {
            width: 13px;
            height: 13px;
            margin-left: -13px;
            top: 13px;
        }
    }
    &.success,
    &.success::before {
        background: $success;
    }
    &.secondary {
        border: 1px solid $gray-400;
        background: $white;
    }
    &.danger {
        background: $danger;
    }
    &.warning,
    &.warning::before {
        background: $warning;
    }
    &.info {
        background: $info;
    }
    &.disabled {
        background: $gray-400;
    }
}
