@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.reportingPage {
    .leftWrap {
        width: 95%;
        background: $gray-100;
        border-right: 30px solid $teal-600;
        padding: 2rem 2.75rem 2rem 4rem;

        &.INTERACTIVE {
            padding-left: 0;
        }
    }
    .fds-pl {
        .fds-pl-container {
            margin-bottom: 0;
        }
    }
}