.wrap {
    width: 100%;
    z-index: 10000;
    margin: auto;
    position: fixed;
    top: calc(50% - 20px);
    left: 0;
    text-align: center;
    .loadTxt {
        font-weight: bold;
    }
    .loadWrap {
        width: fit-content;
        margin: auto;
        background: #316670;
        padding: 1rem 2rem;
        opacity: 0.8;
        border-radius: 8px;
        box-shadow: 0px 0px 6px #316670;
        color: white !important;
        .text-primary {
            color: white !important;
        }
    }
}
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: white;
    opacity: 0.2;
}
