.chartsPage {
    .tooltip {
        z-index: 100000 !important;
    }
    .chart {
        margin-top: -8px;
        .options {
            position: absolute;
            top: 20px;
            right: 16px;
        }
    }
    .chartSection {
        height: 100%;
        position: relative;
        box-shadow:
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12),
            0 1px 3px 0 rgba(0, 0, 0, 0.2);
        //overflow: hidden;
        border-radius: 4px;
        .tab-content{
            padding-top: 0;
        }
        .chartSection {
            box-shadow: none;
        }
        .trend {
            margin-top: -15px;
            margin-right: -5px;
            .trendText {
                font-size: 20px;
            }
        }
        h2,
        .h2 {
            width: calc(100% - 4rem);
            font-size: 1.25rem;
        }
    }

    .tab-pane {
        .compareTrendOptions,
        .compareTrendOptions_cardWrap {
            padding-bottom: 1rem;
        }
    }

    .col.dropDownWrap {
        text-align: right;
        .dropdown {
            margin: 0 0 0 1rem;
            i {
                font-size: 1.3rem;
            }
            .dropdown-item {
                i {
                    position: relative;
                    top: 3px;
                }
            }
            button {
                padding: 0;
            }
        }
    }
    .printWrap {
        .dropdown {
            i {
                position: relative;
                top: 3px;
                font-size: 1.3rem;
            }
        }
    }
    .wrapTabs + .tab-content > .active {
        display: flex;
    }
    .fieldTabs {
        position: absolute;
        right: 115px;
        top: 12px;
        z-index: 1;
        font-size: .85rem;
        font-weight: 600;
    }
}

.rightLabel {
    font-size: 1rem;
    font-weight: 500;
    color: gray;
    margin-left: auto;
}

.chartDataWrap {
    border: 1px solid #ccc;
}

.chartDataWrap,
.chartDataOnlyWrap {
    text-wrap: wrap;
    overflow: auto;
    border-radius: 4px;
    max-height: 500px;
    margin-top: 0.5rem;
}

.chartDataOnlyWrap {
    table {
        margin: 0;
    }
}

@media print {
    .no-print,
    #viewAsWrap,
    #feedBackBtn,
    .navbar-toggler,
    .printWrap {
        display: none !important;
    }
    .chartsPage {
        .fds-pl-jumbotron {
            background: white;
            padding: 0px;
        }
        .fds-pl-jumbotron *,
        .dropDownWrap .dropdown {
            display: none;
        }
        .shadow {
            box-shadow: none !important;
        }
        .dropDownWrap {
            padding-top: 10px;
        }
        .pageBreak {
            page-break-before: always;
        }
    }
}

.summaryWrap {
    height: 100%;
    .summary,
    .col,
    .card {
        min-height: 130px;
        //height: 100%;
        overflow: visible;
        &.wrapCards {
            flex-wrap: wrap;
        }
        .trendText {
            font-size: 1.2rem;
            font-weight: 600;
        }
        .summaryCardLabel {
            text-transform: uppercase;
            margin-top: 1rem;
            color: gray;
        }
    }
    i.icons {
        top: 3.4rem;
    }
}

.compareTrendOptions {
    background-color: white;
    margin: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .compareChecks {
        background-color: rgb(249, 247, 247);
        margin-right: 8px;
        padding: 2px 8px;
        border: 1px solid #ecebeb;
        border-radius: 4px;
        input[type="checkbox"] {
            top: 3px;
            + label {
                font-weight: 600;
            }
        }
    }
}
.compareTrendOptions_cardWrap {
    background-color: white;
    margin: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
