@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.accordionPage {
    position: fixed;
    z-index: 100;
    bottom: 1rem;
    right: 1rem;
    width: 100%;
    min-width: calc(100vw - 55vw) !important;
    max-width: 45rem;
    justify-content: right;
    margin-right: 0px;
    font-size: 1rem;

    .no-style {
        width: auto !important;
    }

    h6 {
        width: fit-content;
        position: absolute;
        font-weight: 800 !important;
        bottom: 1rem;
        right: 2rem;
        z-index: 101;
        cursor: pointer;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    &.row > * {
        padding: 0px !important;
    }

    .accordion-item .accordion-header .accordion-button:not(.collapsed) {
        color: $primary !important;
    }

    .accordion-item .accordion-header .accordion-button {
        font-weight: 700;
    }

    .clearIcon {
        text-align: right;
        width: 32px;
        background-color: #f3f3f3;
        border-radius: 4px 4px 0 0;
        margin-bottom: -4px;
        padding: 4px !important;
        i {
            color: #949494;
            font-size: 1.5rem;
            cursor: pointer;
            &:hover {
                color: #6a6969;
            }
        }
    }

    .releaseIcon {
        padding: 8px !important;
        background-color: $teal-arc-brand;
        flex: 0;
        border-radius: 50%;
        font-size: 2rem;
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);

        &:focus:before {
            border-radius: 50%;
            display: none;
        }

        &:focus-visible:before {
            display: block;
        }
    }

    .accordionParent {
        overflow: hidden;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.16);
        max-height: 32rem;
        overflow-y: auto;

        .releaseTitle {
            color: $primary;
            font-weight: bold;
        }
    }
}
