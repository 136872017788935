.groupingsPage {
    .dropdown .dropdown-menu.show {
        transform: translateX(10px) !important;
    }
    .card {
        overflow: visible;
    }
}

table {
    td {
        vertical-align: top !important;
    }
    th.right,
    td.right {
        text-align: right;
    }
}
