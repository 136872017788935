.dropdown {
  background: transparent;

  .dropdown-toggle {
    white-space: pre-line;

    &:after {
      color: #fff;
      font-size: 0.8em;
      vertical-align: 0;
    }

    &.btn-lg {
      &:after {
        top: 15px;
      }
    }
  }
}

.prevNextSpace {
  min-height: 2rem;
}

.topRunButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}